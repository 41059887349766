<template>
  <header id="header" class="montserrat-regular">
    <!--Start Header Top-->
    <div class="row header-top">
      <div class="header-contact-info d-flex col-md-6">
        <ul class="social-owner">
          <li class="mr-3"><i class="fas fa-mobile-alt"> </i></li>
        </ul>
        <ul id="header-number">
          <li><a href="tel:39907981"> 3990-7981 | </a></li>
          <li><a href="tel:37507897"> 3750-7897 | </a></li>
          <li><a href="tel:+5491169761773"> + 54 9 11 6976-1773 </a></li>
        </ul>
      </div>
      <div class="col-md-6 header-social">
        <ul class="social-owner">
          <!-- <li><a href="#"><span>Siguenos en: </span></a></li> -->
          <li class="mr-2">
            <a href="https://www.instagram.com/zama.broker/" target="_blank">
              <i class="fab fa-instagram" />
            </a>
          </li>
          <li class="mr-2">
            <a href="https://www.facebook.com/zama.broker" target="_blank">
              <i class="fab fa-facebook-f" />
            </a>
          </li>
          <li class="mr-2">
            <a
              href="https://www.linkedin.com/in/zama-broker-28bb2815b/"
              target="_blank"
            >
              <i class="fab fa-linkedin" />
            </a>
          </li>
          <li>
            <a href="mailto:info@zamabroker.com.ar">
              <i class="far fa-envelope" />
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!--End Header Top-->

    <!--Start Main Menu-->
    <nav class="main_navbar">
      <!-- MAIN NAVBAR -->
      <div class="main_navbar-container container">
        <router-link to="/">
          <img class="header-logo" src="img/zama-logoblanco.png" alt="" />
        </router-link>
        <div class="navbar-options-container">
          <nav role="navigation">
            <ul class="mt-2 navbar-options main-menu">
              <li>
                <router-link to="/somos-zama"> SOMOS ZAMA </router-link>
              </li>
              <li>
                <router-link to="/nuestros-productos">
                  NUESTROS PRODUCTOS
                </router-link>
                <ul class="submenu">
                  <li data-goto="#seguroPatrimonial">
                    <router-link to="/nuestros-productos">
                      Seguros Patrimoniales
                    </router-link>
                  </li>
                  <li data-goto="#retiroInversion">
                    <router-link to="/nuestros-productos">
                      Vida, Retiro e Inversión
                    </router-link>
                  </li>
                  <li data-goto="#riesgoTrabajo">
                    <router-link to="/nuestros-productos">
                      Riesgos de trabajo
                    </router-link>
                  </li>
                </ul>
              </li>
              <li>
                <router-link to="/productores"> PRODUCTORES </router-link>
                <ul class="submenu">
                  <li data-goto="#nuestrosProductos">
                    <router-link to="/productores">
                      Nuestros productores
                    </router-link>
                  </li>
                  <li data-goto="#capacitaciones">
                    <router-link to="/productores">
                      Capacitaciones
                    </router-link>
                  </li>
                  <li data-goto="#careerPlan">
                    <router-link to="/productores"> Beneficios </router-link>
                  </li>
                  <li data-goto="#byPartOfZama">
                    <router-link to="/productores">
                      Sé parte de Zama
                    </router-link>
                  </li>
                </ul>
              </li>
              <li>
                <router-link to="alianzas"> ALIANZAS </router-link>
              </li>
              <li>
                <router-link to="contactos"> CONTACTO </router-link>
              </li>
            </ul>
            <div id="menuToggle">
              <input type="checkbox" />
              <span></span>
              <span></span>
              <span></span>
              <ul id="menu">
                <router-link class="text-center" to="/">
                  <img
                    class="header-logo"
                    src="img/zama-logocolor.png"
                    alt=""
                  />
                </router-link>
                <router-link to="/somos-zama">
                  <h6 class="menu-items-mobile">SOMOS ZAMA</h6>
                </router-link>
                <router-link to="/nuestros-productos">
                  <h6 class="menu-items-mobile">NUESTROS PRODUCTOS</h6>
                  <ul class="submenu">
                    <li data-goto="#seguroPatrimonial">
                      <router-link to="/nuestros-productos">
                        - Seguros Patrimoniales
                      </router-link>
                    </li>
                    <li data-goto="#retiroInversion">
                      <router-link to="/nuestros-productos">
                        - Vida, Retiro e Inversión
                      </router-link>
                    </li>
                    <li data-goto="#riesgoTrabajo" class="mb-3">
                      <router-link to="/nuestros-productos">
                        - Riesgos de trabajo
                      </router-link>
                    </li>
                  </ul>
                </router-link>
                <router-link to="/productores">
                  <h6 class="menu-items-mobile">PRODUCTORES</h6>
                  <ul class="submenu">
                    <li data-goto="#nuestrosProductos">
                      <router-link to="/productores">
                        - Nuestros productores
                      </router-link>
                    </li>
                    <li data-goto="#capacitaciones">
                      <router-link to="/productores">
                        - Capacitaciones
                      </router-link>
                    </li>
                    <li data-goto="#careerPlan">
                      <router-link to="/productores">
                        - Beneficios
                      </router-link>
                    </li>
                    <li data-goto="#byPartOfZama" class="mb-3">
                      <router-link to="/productores">
                        - Sé parte de Zama
                      </router-link>
                    </li>
                  </ul>
                </router-link>
                <router-link to="alianzas">
                  <h6 class="menu-items-mobile">ALIANZAS</h6>
                </router-link>
                <router-link to="contactos">
                  <h6 class="menu-items-mobile">CONTACTO</h6>
                </router-link>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </nav>
    <!--End Main Menu-->
  </header>
</template>
<script>
export default {
  mounted() {
    window.onclick = function (event) {
      console.log("event.srcElement", event.srcElement);
      const selected = document.querySelector("#menuToggle input");
      console.log("selected.checked", selected.checked);
      if (selected.checked != (event.srcElement == selected)) {
        selected.checked = false;
      }
    };
  },
};
</script>
<style>
.social-owner li {
  width: 25px;
  height: 25px;
  line-height: 20px;
  text-align: center;
  margin: 2px;
  padding: 2px;
  border-radius: 50%;
  background: white;
}
.social-owner li i {
  font-size: 15px;
  color: var(--light-orange) !important;
}
.menu-items-mobile {
  font-size: 15px;
}
</style>
