import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import animateScrollTo from "animated-scroll-to";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import("./css/style.css");
import("./css/bootstrap.css");

const handleNavigation = (ele) => {
  console.log("ele.dataset", ele.dataset);
  if (ele.dataset.goto) {
    setTimeout(() => {
      const element = document.querySelector(ele.dataset.goto);
      animateScrollTo(element, {});
    }, 200);
  }
  // console.log("ele", ele);
};
router.afterEach(() => {
  setTimeout(() => {
    const elements = document.querySelectorAll(".submenu li, .goto");
    console.log("elements", elements);
    elements.forEach((ele) => {
      ele.removeEventListener(
        "click",
        function () {
          console.log("this", this);
          handleNavigation(ele);
        },
        true
      );
      ele.addEventListener(
        "click",
        function () {
          console.log("this", this);
          handleNavigation(ele);
        },
        true
      );
    });
  });
});

const mainApp = createApp(App);
mainApp.use(store).use(router).mount("#app");
mainApp.use(VueSweetalert2);
