import { createRouter, createWebHistory } from "vue-router";

// Layouts

import MainLayout from "../layouts/MainLayout.vue";

const routes = [
  {
    path: "/",
    redirect: "Home",
    component: MainLayout,
    children: [
      {
        path: "/",
        name: "Home",
        component: () => import("@/views/Home.vue"),
      },
      {
        path: "/productores",
        name: "Producers",
        component: () => import("@/views/Producers.vue"),
      },
      {
        path: "/somos-zama/",
        name: "WeAre",
        component: () => import("@/views/WeAre.vue"),
      },
      {
        path: "/alianzas/",
        name: "Alliance",
        component: () => import("@/views/Alliance.vue"),
      },
      {
        path: "/contactos",
        name: "Contacts",
        component: () => import("@/views/Contacts.vue"),
      },
      {
        path: "/nuestros-productos/",
        name: "OurProducts",
        component: () => import("@/views/OurProducts.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: "history",
  routes,
});

export default router;
