<template>
  <router-view />
</template>

<script>
// @ is an alias to /src
// import Header from "@/components/Header.vue";
// import Producers from "@/views/Producers.vue";
// import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    // Header,
    // Producers,
    // Footer,
  },
};
</script>
<style></style>
