<template>
  <div class="content">
    <transition :duration="200" mode="out-in" v-cloak>
      <keep-alive>
        <div>
          <Header />
          <router-view :key="$route.fullPath"></router-view>
          <a
            href="https://wa.me/5491169761773"
            target="_blank"
            color="#4dc247"
            class="whatsapp"
          >
            <img width="50" src="/img/whatsapp-logo.png" alt="" />
          </a>

          <Footer />
        </div>
      </keep-alive>
    </transition>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
// import Producers from "@/views/Producers.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
};
</script>
<style>
.v-cloak {
  display: none;
}
</style>
