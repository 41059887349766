<template>
  <footer id="footer" class="montserrat-regular">
    <div class="footer-top">
      <div class="container">
        <!--Start Row-->
        <div class="row">
          <!--Start Footer About-->
          <div class="col-lg-2 col-md-12 text-center mb-2">
            <div class="footer-latest-news">
              <!--Start Recent Post Single-->
              <div class="post-img">
                <img
                  src="../../public/img/zama-logoblanco.png"
                  class="img-fluid"
                  alt="Image"
                />
              </div>
              <!--End Recent Post Single-->
            </div>
            <!--<div class="footer-newsletter">
								<h4 class="color-white">Subscribe Now</h4>
								<form>
									<input type="email" name="email"><input type="submit" value="Subscribe">
								</form>
						</div>-->
          </div>
          <!--End Footer About-->

          <!--Start Footer Links-->
          <div class="col-lg-4 col-md-6 mb-3 wow fadeInUp" data-wow-delay=".4s">
            <div class="footer-latest-news">
              <!--Start Recent Post Single-->
              <div class="recent-post-single fix">
                <div class="post-img">
                  <a href="#"><img src="img/footer-1.png" alt="Image" /></a>
                </div>
                <div class="post-cont">
                  <h5 class="color-white">NUESTRAS OFICINAS</h5>
                  <ul class="color-white">
                    <li>
                      <span>
                        Avenida Dorrego 1789 - Piso 3 Oficina 305 (C1414CKM)
                        C.A.B.A
                      </span>
                    </li>
                    <li>
                      <span>
                        Gral. Rodriguez 761 - 4 ”A” (B1842DIO) Monte. Grande.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--End Recent Post Single-->
            </div>
          </div>
          <!--End Footer Links-->

          <!--Start Footer Latest News-->
          <div class="col-lg-3 col-md-6 mb-3">
            <div class="footer-latest-news">
              <!--Start Recent Post Single-->
              <div class="recent-post-single fix">
                <div class="post-img">
                  <a href="#"><img src="img/footer-2.png" alt="Image" /></a>
                </div>
                <div class="post-cont">
                  <h5 class="color-white">TEL&Eacute;FONOS</h5>
                  <ul class="color-white">
                    <li>
                      <a href="tel:39907981"> 3990-7981</a> |
                      <a href="tel:39907980">3990-7980</a>
                    </li>
                    <li><a href="tel:37507897"> 3750-7897</a></li>
                    <li><a href="tel:5491169761773"> +54 9 11-6976-1773</a></li>
                  </ul>
                </div>
              </div>
              <!--End Recent Post Single-->
            </div>
          </div>
          <!--End Footer Latest News-->
          <!--Start Footer Instagram-->
          <div class="col-lg-3 col-md-6 mb-3 wow fadeInUp" data-wow-delay=".8s">
            <div class="footer-latest-news">
              <!--Start Recent Post Single-->
              <div class="recent-post-single fix">
                <div class="post-img">
                  <a href="#"><img src="img/footer-3.png" alt="Image" /></a>
                </div>
                <div class="post-cont">
                  <h5 class="color-white">EMAIL</h5>
                  <ul class="color-white">
                    <a href="mailto:info@zamabroker.com.ar">
                      <li>info@zamabroker.com.ar</li>
                    </a>
                  </ul>
                </div>
              </div>
              <!--End Recent Post Single-->
            </div>
          </div>
          <!--End Footer Instagram-->
        </div>
        <!--End Row-->
      </div>
    </div>
    <!--End Footer Top-->
    <!--Start Footer Bottom-->
    <div class="footer-bottom">
      <div class="footer-social-area bg-white py-3">
        <div class="col-md-12 d-flex justify-content-center p-0">
          <div class="row flex align-items-center">
            <a
              href="https://www.linkedin.com/in/zama-broker-28bb2815b/"
              target="_blank"
            >
              <i class="text-white fab fa-linkedin-in" />
            </a>
            <a href="https://www.instagram.com/zama.broker/" target="_blank">
              <i class="text-white fab fa-instagram" />
            </a>
            <a href="https://www.facebook.com/zama.broker" target="_blank">
              <i class="text-white fab fa-facebook-f" />
            </a>
          </div>
        </div>
      </div>
      <div
        class="row py-4 text-center footer-contact-container align-items-center"
      >
        <div class="col-lg-2 col-md-6">
          <h6>
            N° de Inscripción en SSN <br />
            Registro N° 1410
          </h6>
        </div>
        <div class="col-lg-2 col-md-6">
          <h6>
            Atención al asegurado: <br />
            <a href="tel:+5491169761773">+54 9 11 6976-1773</a>
          </h6>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 mb-sm-0 mb-4">
          <img src="img/logo-pdp.png" class="w-50" alt="" />
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <img class="w-75" src="img/ssn_horizontal_web__1.png" alt="" />
        </div>
        <div class="col-lg-2 col-md-12 col-sm-12">
          <img src="img/qr.png" class="my-4 qrImg" />
        </div>
      </div>
      <p class="color-white text-center py-3">
        &copy; Copy 2018. Todos los derechos reservados ZAMA. Realizado por
        Espacio Sol
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  props: {
    msg: String,
  },
};
</script>
